@import 'src/styles/variables';
@import 'src/styles/mixins';

.container {
  @include cardContainer;
  margin-bottom: 16px;
}

.title {
  font-weight: $boldFontWeight;
  margin-bottom: 24px;
}

.actionItems {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
