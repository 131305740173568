@import 'src/styles/mixins';

.button {
  margin-top: 24px;
  width: 100%;

  @include sm {
    max-width: 720px;
    margin: auto;
  }
}
