@import 'src/styles/variables';
@import 'src/styles/mixins';

.innerContainer {
  @include cardContainer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  margin-bottom: 16px;
}

.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.totalDebtLabel {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: $boldFontWeight;
  margin-bottom: 24px;
}

.totalDebtAmount {
  font-size: 24px;
  font-weight: $semiBoldFontWeight;
}

.sectionTitle {
  font-size: 14px;
  font-weight: $semiBoldFontWeight;
}

.header.centered {
  justify-content: center;

  .sectionTitle {
    margin-right: 20px;
  }
}

.header .centered {
  display: flex;
  justify-content: center;
}
